<template>
	<div style="height: 100%;">
		<div class="page-title">
			<span style="font-size: 20px;">排查分布图</span>
		</div>
		<div class="bg-white p-2 m-2" style="height: calc(100% - 80px);">
			<el-row style="height: 100%;">
				<el-col :span="16" style="height: 100%;">
					<div>如果标记不显示，请刷新页面</div>
					<div class="app-map" id="container" :style="style"></div>
				</el-col>
				<el-col :span="8">
					<div class="ml-3" style="margin-top: 50px;">
						<div class="label">
							<h6>单位筛选：</h6>
						</div>
						<div class="d-flex align-items-center">
							<el-select style="width: 100px;" size="mini" @change="handleFirstChange"
								v-model="search.first_id" placeholder="请选择支队">
								<el-option label="请选择" :key="0" :value="0">
								</el-option>
								<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
									:value="item.unit_id">
								</el-option>
							</el-select>
							<el-select size="mini" @change="handleSecondChange" v-model="search.second_id"
								placeholder="请选择大队">
								<el-option label="请选择" :key="0" :value="0">
								</el-option>
								<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
									:value="item.unit_id">
								</el-option>
							</el-select>
							<el-select size="mini" @change="handleThirdChange" v-model="search.third_id"
								placeholder="请请选择街道">
								<el-option label="请选择" :key="0" :value="0">
								</el-option>
								<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
									:value="item.unit_id">
								</el-option>
							</el-select>
						</div>
						<div class="label">
							<h6>网格筛选：</h6>
						</div>
						<div class="d-flex align-items-center">
							<el-select size="mini" v-model="search.reseau_id" placeholder="请请选择网格">
								<el-option label="请选择" :key="0" :value="0">
								</el-option>
								<el-option v-for="item in reseauLists" :label="item.name" :key="item.reseau_id"
									:value="item.reseau_id">
								</el-option>
							</el-select>
							<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
							</el-button>
						</div>
						<div class="d-flex align-items-center mt-3">
							危险状态：
							<el-button class="ml-2" @click="handleEarlyWarning(0)"
								:type="search.early_warning == 0 ? 'primary' : ''" size="mini">全部</el-button>
							<el-button @click="handleEarlyWarning(40)"
								:type="search.early_warning == 40 ? 'primary' : ''" size="mini">高危</el-button>
							<el-button @click="handleEarlyWarning(30)"
								:type="search.early_warning == 30 ? 'primary' : ''" size="mini">危险</el-button>
							<el-button @click="handleEarlyWarning(20)"
								:type="search.early_warning == 20 ? 'primary' : ''" size="mini">一般</el-button>
							<el-button @click="handleEarlyWarning(10)"
								:type="search.early_warning == 10 ? 'primary' : ''" size="mini">良好</el-button>
						</div>
					</div>
					<div class="ml-3 mt-3">
						<p>
						<h6>三合一场所：合计 {{mapData.total_count}} 处</h6>
						</p>
					</div>
					<div class="ml-3 mt-3">
						<div class="d-flex  align-items-center">
							<div
								style="width: 45px;height: 30px;background-color: rgb(195,5,33);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								高危
							</div>
							<div class="ml-2">{{mapData.high_risk_count}} 处</div>
						</div>
						<div class="d-flex mt-2 align-items-center">
							<div
								style="width: 45px;height: 30px;background-color: rgb(250, 186, 38);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								危险
							</div>
							<div class="ml-2">{{mapData.risk_count}} 处</div>
						</div>
						<div class="d-flex mt-2 align-items-center">
							<div
								style="width: 45px;height: 30px;background-color: rgb(50,180,237);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								一般
							</div>
							<div class="ml-2">{{mapData.ordinary_count}} 处</div>
						</div>
						<div class="d-flex mt-2 align-items-center">
							<div
								style="width: 45px;height: 30px;background-color: rgb(108, 189, 40);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								良好
							</div>
							<div class="ml-2">{{mapData.well_count}} 处</div>
						</div>
						<p></p>
						<p></p>
						<p>整改率：{{mapData.edit_percent}}%</p>
					</div>
					<div class="ml-3 mt-3">
						<div class="d-flex mt-3 align-items-center" v-for="item in unitStatisticsData">
							<div style="width: 130px;">{{item.unit_name}}</div>
							<div
								style="width: 45px;height: 30px;background-color: rgb(195,5,33);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								高危
							</div>
							<div class="ml-2">{{item.high_risk_count}} 处</div>
							<div class="ml-2"
								style="width: 45px;height: 30px;background-color: rgb(250, 186, 38);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								危险
							</div>
							<div class="ml-2">{{item.risk_count}} 处</div>
							<div class="ml-2"
								style="width: 45px;height: 30px;background-color: rgb(50,180,237);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								一般
							</div>
							<div class="ml-2">{{item.ordinary_count}} 处</div>
							<div class="ml-2"
								style="width: 45px;height: 30px;background-color: rgb(108, 189, 40);line-height: 30px;font-weight: 800;color:#fff;text-align: center;">
								良好
							</div>
							<div class="ml-2">{{item.well_count}} 处</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		components: {},
		computed: {
			style() {
				let width = '100%';
				let height = '100%';
				if (this.width) {
					width = this.width + (isNaN(this.width) ? '' : 'px');
				}
				if (this.height) {
					height = this.height + (isNaN(this.height) ? '' : 'px');
				}
				return `width:${width};height:${height};`;
			},
		},
		data() {
			return {
				width: 0,
				height: 0,
				maps: false,
				map: false,
				firstList: [],
				secondList: [],
				thirdList: [],
				unitStatisticsData: [],
				search: {
					s: 'store/reseau.check/listsMap',
					early_warning: 0,
					keyword: '',
					first_id: 0,
					second_id: 0,
					third_id: 0,
					reseau_id: 0,
					page: 1,
				},
				reseauLists: [],
				reseauSearch: {
					s: 'store/reseau.check/reseauLists',
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
				lists: [],
				mapData: {
					total_count: 0,
					well_count: 0,
					ordinary_count: 0,
					risk_count: 0,
					high_risk_count: 0,
				},
				dot: null,
				map: null,
			}
		},
		mounted() {
			this.getList()
			this.getReseauList()
			this.getUnitList();
			this.getUnitStatistics();
		},
		methods: {
			handleEarlyWarning(early_warning) {
				this.search.early_warning = early_warning;
				this.toSearch();
			},
			toSearch() {
				this.map.destroy();
				this.getList()
				this.getReseauList()
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
				this.getReseauList()
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
				this.getUnitStatistics();
			},
			handleThirdChange(third_id) {
				this.getReseauList()
				this.getUnitStatistics();
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data
					this.search.first_id = this.firstList[0].unit_id;
					this.handleFirstChange(this.search.first_id);
				}).catch(err => {});
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.getMapData()
					this.lists = res.data.data;
					this.initMap()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getReseauList() {
				this.reseauSearch.first_id = this.search.first_id
				this.reseauSearch.second_id = this.search.second_id
				this.reseauSearch.third_id = this.search.third_id
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.reseauSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.reseauLists = res.data.data;
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getMapData() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/reseau.check/mapData',
						first_id: this.search.first_id,
						second_id: this.search.second_id,
						third_id: this.search.third_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.mapData = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getUnitStatistics() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/reseau.check/unitStatistics',
						first_id: this.search.first_id,
						second_id: this.search.second_id,
						third_id: this.search.third_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.unitStatisticsData = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 初始化地图
			initMap() {
				let self = this;
				let center = new window.TMap.LatLng(23.527538, 116.364921); // 默认坐标
				if (this.lists.length > 0) {
					center = new window.TMap.LatLng(this.lists[0].latitude, this.lists[0].longitude); // 默认坐标
				}


				//初始化地图
				this.map = new window.TMap.Map("container", {
					zoom: 13, //设置地图缩放级别
					center: center, //设置地图中心点坐标
					mapStyleId: "style1", //个性化样式
					baseMap: {
						type: "vector",
						// features: ["base", "building3d"], // 隐藏矢量文字
					},
				});

				//将数据分类并添加styleId（数据为js文件引入）
				this.lists.forEach(function(item, index) {
					item.lng = parseFloat(item.longitude)
					item.lat = parseFloat(item.latitude)
					if (item.early_warning == 10) {
						item.styleId = "StyleColor1";
						item.hierarchy = 4;
					} else if (item.early_warning == 20) {
						item.styleId = "StyleColor2";
						item.hierarchy = 3;
					} else if (item.early_warning == 30) {
						item.styleId = "StyleColor3";
						item.hierarchy = 2;
					} else {
						item.styleId = "StyleColor4";
						item.hierarchy = 1;
					}
				});

				//初始化散点图并添加至map图层
				this.dot = new window.TMap.visualization.Dot({
					styles: {
						StyleColor1: {
							type: "circle", //圆形样式
							fillColor: "rgb(0, 85, 0)", //填充颜色
							radius: 3, //原型半径
						},
						StyleColor2: {
							type: "circle", //圆形样式
							fillColor: "rgb(0, 85, 255)", //填充颜色
							radius: 3, //原型半径
						},
						StyleColor3: {
							type: "circle", //圆形样式
							fillColor: "rgb(255, 170, 0)", //填充颜色
							radius: 3, //原型半径
						},
						StyleColor4: {
							type: "circle", //圆形样式
							fillColor: "rgb(170, 0, 0)", //填充颜色
							strokeWidth: 0, //边线宽度
							radius: 3, //原型半径
						},
					},
				}).addTo(this.map)
				this.dot.setData(
					this.lists //显示层级排序
				);
			},
		},
	}
</script>

<style>

</style>
